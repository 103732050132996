// 数据api请求接口
export default {
  // 首页数据
  indexinfo: {
    url: "/index/index/homne",
    method: "post",
  },
  //资讯列表
  information: {
    url: "/index/information/lists",
    method: "post",
  },
  //获取城市
  getAreaList: {
    url: "/index/common.area/getAreaList",
    method: "post",
  },
  //城市联动
  getAreaListByPid: {
    url: "/index/common.area/getAreaListByPid",
    method: "post",
  },
  //获取职位
  getCategoryList: {
    url: "/api/common/getCategoryList",
    method: "post",
  },
  //初始化（参数）
  init: {
    url: "/index/information/init",
    method: "post",
  },
  //资讯详情
  informationdetail: {
    url: "/index/information/detail",
    method: "post",
  },
  //订单详情
  orderDetail: {
    url: "/index/common.order/getOrderInfo",
    method: "post",
  },
  //订单取消
  orderCancel: {
    url: "/index/common.order/cancel",
    method: "post",
  },
  //评论列表
  commentList: {
    url: "/index/user.comment/lists",
    method: "post",
  },
  //发送验证码
  smssend: {
    url: "/api/sms/send",
    method: "post",
  },
  //验证码登陆
  mobilelogin: {
    url: "/api/user/mobilelogin",
    method: "post",
  },
  //注册
  register: {
    url: "/api/user/register",
    method: "post",
  },
  //账号密码登陆
  accountlogin: {
    url: "/api/user/login",
    method: "post",
  },
  //消息列表
  message_notice: {
    url: "/index/message_notice/lists",
    method: "post",
  },
  //添加资讯
  addinfomation: {
    url: "/index/information/add",
    method: "post",
  },
  //我发布的列表
  getMyList: {
    url: "/index/information/getMyList",
    method: "post",
  },
  //操作资讯
  userSaveData: {
    url: "/index/information/userSaveData",
    method: "post",
  },
  //浏览记录
  history_list: {
    url: "/index/user.views/lists",
    method: "post",
  },
  //删除记录
  history_del: {
    url: "/index/user.views/del",
    method: "post",
  },
  //清空记录
  history_empty: {
    url: "/index/user.views/empty",
    method: "post",
  },
  //收藏记录
  collect_list: {
    url: "/index/user.collect/lists",
    method: "post",
  },
  //添加收藏记录
  collect: {
    url: "/index/user.collect/operation",
    method: "post",
  },
  //取消收藏记录
  collect_cancel: {
    url: "/index/user.collect/cancel",
    method: "post",
  },
  //我的会员信息
  myMember: {
    url: "/index/user.vip/myMember",
    method: "post",
  },
  //获取VIP套餐列表
  vipsetting: {
    url: "/index/user.vip/setting",
    method: "post",
  },
  //提交VIP订单/续费
  vipsubmitOrder: {
    url: "/index/user.vip/submitOrder",
    method: "post",
  },
  //提交单独购买订单
  vipsubmitSingle: {
    url: "/index/user.vip/submitSingle",
    method: "post",
  },
  //订单支付（微信、支付宝、余额、线下支付）
  paysubmit: {
    url: "/index/common/pay/submit",
    method: "post",
  },
  //订单列表
  getOrderList: {
    url: "/index/common.order/getOrderList",
    method: "post",
  },
  //案例列表
  caselist: {
    url: "/index/cases/lists",
    method: "post",
  },
  //seo优化
  caseseo: {
    url: "/index/common/seo/getSeoData",
    method: "post",
  },

  //案例详情
  casedetail: {
    url: "/index/cases/detail",
    method: "post",
  },
  //团餐企业列表
  enterpriselist: {
    url: "/index/enterprise/enterprise/lists",
    method: "post",
  },
  //团餐企业详情
  enterprisedetail: {
    url: "/index/enterprise/enterprise/detail",
    method: "post",
  },
  //企业服务列表
  enterpriseserverlist: {
    url: "/index/enterprise/service/lists",
    method: "post",
  },
  //企业服务详情
  enterpriseserverdetail: {
    url: "/index/enterprise/service/detail",
    method: "post",
  },
  //企业案例列表
  enterprisecaseslist: {
    url: "/index/enterprise/cases/lists",
    method: "post",
  },
  //企业服务详情
  enterprisecasedetail: {
    url: "/index/enterprise/cases/detail",
    method: "post",
  },
  //团餐企业初始化（类型）
  enterpriseinit: {
    url: "/index/enterprise/enterprise/init",
    method: "post",
  },
  //行业资讯
  industryinformation: {
    url: "/index/industry_information/lists",
    method: "post",
  },
  //举报
  reportadd: {
    url: "/index/user.report/add",
    method: "post",
  },
  //资讯详情
  industryinformationdetail: {
    url: "/index/industry_information/detail",
    method: "post",
  },
  //获取用户信息
  getUserInfo: {
    url: "/api/user/index",
    method: "post",
  },
  //消息列表
  messagenoticelist: {
    url: "/index/message_notice/lists",
    method: "post",
  },
  //客服列表
  customer_service: {
    url: "/index/customer_service/lists",
    method: "post",
  },
  //文章列表
  articlelists: {
    url: "/index/common.article/lists",
    method: "post",
  },
  //文章详情
  articledetail: {
    url: "/index/common.article/details",
    method: "post",
  },
  //意见反馈
  feedbackadd: {
    url: "/index/user.feedback/add",
    method: "post",
  },
  //修改用户信息
  editprofile: {
    url: "/api/user/profile",
    method: "post",
  },
  //个人认证
  authenticrealname: {
    url: "/index/authentic/realname/submit",
    method: "post",
  },
  //企业认证
  authenticenterprise: {
    url: "/index/authentic/enterprise/submit",
    method: "post",
  },
  //个人认证详情
  getrealdetail: {
    url: "/index/authentic/realname/detail",
    method: "post",
  },
  //企业认证详情
  getcompanyrealdetail: {
    url: "/index/authentic/enterprise/detail",
    method: "post",
  },
  //企业认证初始化
  authenticeinit: {
    url: "/index/authentic/enterprise/init",
    method: "post",
  },
  //资讯编辑
  informationedit: {
    url: "/index/information/edit",
    method: "post",
  },
  //计算食堂面积
  canteenArea: {
    url: "/index/calculator/canteenArea",
    method: "post",
  },
  //计算就餐人数
  dinersNumber: {
    url: "/index/calculator/dinersNumber",
    method: "post",
  },
  //食堂评估
  canteenEvaluation: {
    url: "/index/calculator/canteenEvaluation",
    method: "post",
  },
  //充值
  recharge: {
    url: "/index/user.recharge/submit",
    method: "post",
  },
  //充值
  vipBenefits: {
    url: "/index/user.vip/vipBenefits",
    method: "post",
  },
  //成功案例
  vipanli: {
    url: "/index/cases/lists",
    method: "post",
  },
  //支付宝跳转支付 /index/common/pay/init
  payinit: {
    url: "/index/common/pay/init",
    method: "post",
  },
  //检测订单是否支付
  checkOrderPayStatus: {
    url: "/index/common/order/checkOrderPayStatus",
    method: "post",
  },
  //提交评论
  comment: {
    url: "/index/user.comment/comment",
    method: "post",
  },
  //回复评论
  reply: {
    url: "/index/user.comment/reply",
    method: "post",
  },
  //删除评论
  delComment: {
    url: "/index/user.comment/delComment",
    method: "post",
  },
  //删除回复
  delReply: {
    url: "/index/user.comment/delReply",
    method: "post",
  },
  //忘记密码
  resetpwd: {
    url: "/api/user/resetpwd",
    method: "post",
  },
  //购买单条信息
  submitSingle: {
    url: "/index/user.vip/submitSingle",
    method: "post",
  },
  //下载协议文档
  downfile: {
    url: "/index/user.vip/downAgreement",
    method: "post",
  },
  //查看消息
  seemessage: {
    url: "/index/message_notice/see",
    method: "post",
  },
  //查看消息
  allRead: {
    url: "/index/message_notice/allRead",
    method: "post",
  },
  // 获取积分任务列表
  getTaskList: {
    url: "/index/task/task/getTaskList",
    method: "post",
  },
  // 获取签到首页数据
  getSignInfo: {
    url: "/index/task/signin/getSignInfo",
    method: "post",
  },
  // 立即签到
  dosign: {
    url: "/index/task/signin/dosign",
    method: "post",
  },
  // 积分明细
  moneyLogLists: {
    url: "/index/user.money_log/lists",
    method: "post",
  },
  // 获取我的评论列表
  myCommonLists: {
    url: "/index/user.comment/lists",
    method: "post",
  },
  // 获取我的邀请用户列表
  getInviteList: {
    url: "/index/task.invite/getInviteList",
    method: "post",
  },
  // 获取我的邀请码
  getInviteCode: {
    url: "/index/task.invite/getInviteCode",
    method: "post",
  },
  // 分享后调用
  shareOperation: {
    url: "/index/user.share/operation",
    method: "post",
  },
  // 微信授权根据code检测用户是否绑定
  weChatWebLogin: {
    url: "/api/user/weChatWebLogin",
    method: "post",
  },
  // 第三方登录绑定手机号
  thirdLoginBindMobile: {
    url: "/api/user/thirdLoginBindMobile",
    method: "post",
  },
  // 消息删除（支持批量）
  messageNoticeDel: {
    url: "/index/message_notice/del",
    method: "post",
  },
  // 修改手机号
  changemobile: {
    url: "/api/user/changemobile",
    method: "post",
  },
  // 举报记录
  userReportLists: {
    url: "/index/user.report/lists",
    method: "post",
  },
};

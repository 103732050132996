import Vue from "vue";
import Antd from "ant-design-vue";
import App from "./App";
import "ant-design-vue/dist/antd.css";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import { myrequest } from "@/server";
//路由守卫
router.beforeEach(async (to, from, next) => {
  // 调取接口获取title
  const data = {
    page_path: to.name,
  };
  let res = await myrequest("caseseo", data);
  store.commit("CHANGE_META_INFO", res.data.data);
  next();
});

Vue.prototype.$eventBus = new Vue();
Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(VueMeta);
new Vue({
  router,
  store,
  //动态设置信息
  metaInfo() {
    return {
      title: this.$store.state.meta.title,
      meta: [
        {
          name: "keywords",
          content: this.$store.state.meta.keywords,
        },
        {
          name: "description",
          content: this.$store.state.meta.description,
        },
      ],
    };
  },
  render: (h) => h(App),
}).$mount("#app");
//改变标题方法
function changeTitle(el, binding) {
  const { value } = binding;
  if (el.dataset.title) {
    document.title = el.dataset.title;
  } else if (value && value.title) {
    document.title = "食堂资讯-" + value.title;
  }
}
Vue.directive("blog-title", {
  inserted: function (el, binding) {
    changeTitle(el, binding);
  },
  update: function (el, binding, vnode, oldNode) {
    changeTitle(el, binding);
  },
});

const home = [
  {
    path: "/",
    name: "home-index",
    meta: {
      title: "食堂资讯-首页",
    },
    component: () => import("@/layouts/Main.vue"),
    children: [
      //新
      {
        path: "/canteen-gongchang", //食堂档口
        name: "canteen-gongchang",
        meta: {
          showChild: true,
          showSearch: true,
          title: "食堂工厂",
          breadName: "canteen-gongchang",
        },
        component: () => import("@/views/FoodStall.vue"),
      },
      //旧
      {
        path: "/", //首页
        name: "home",
        meta: {
          breadName: "home",
          title: "食堂资讯-首页",
          showSearch: true,
          showChild: true, // 有此参数之后，页面显示侧边栏部分, 首页左侧导航部分, 下面同理
        },
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/user/inviteFriends", // 邀请好友
        name: "inviteFriends",
        meta: {
          showChild: false,
          title: "邀请好友",
          breadName: "user", // 面包屑匹配
        },
        component: () => import("@/views/inviteFriends.vue"),
      },
      {
        path: "/market-resources", //超市资源
        name: "market-resources",
        meta: {
          breadName: "market-resources",
          title: "超市资源",
          showSearch: true,
          showChild: true, // 有此参数之后，页面显示侧边栏部分
        },
        component: () => import("@/views/MarketResources.vue"),
      },
      {
        path: "/free-resources", //超市资源
        name: "free-resources",
        meta: {
          breadName: "free-resources",
          title: "免费资源",
          showSearch: true,
          showChild: true, // 有此参数之后，页面显示侧边栏部分
        },
        component: () => import("@/views/FreeResources.vue"),
      },
      {
        path: "/free-resources/detail", // 食堂劳务详情
        name: "free-resources-detail",
        meta: {
          showChild: true,
          title: "食堂劳务详情",
          breadName: "free-resources",
        },
        component: () => import("@/views/CanteenTransferDetail.vue"),
      },
      {
        path: "/cooking-equipment", // 厨房设备
        name: "cooking-equipment",
        meta: {
          breadName: "cooking-equipment",
          title: "厨房设备",
          showSearch: true,
          showChild: true, // 有此参数之后，页面显示侧边栏部分
        },
        component: () => import("@/views/CookingEquipment.vue"),
      },
      {
        path: "/search", // 厨房设备
        name: "search",
        meta: {
          breadName: "search",
          title: "信息搜索",
          showSearch: true,
          showChild: true, // 有此参数之后，页面显示侧边栏部分
        },
        component: () => import("@/views/Search.vue"),
      },

      {
        path: "/cooking-equipment/detail", // 厨房设备详情
        name: "cooking-equipment-detail",

        meta: {
          showChild: true,
          title: "厨房设备详情",
          breadName: "cooking-equipment",
        },
        component: () => import("@/views/CookingEquipmentDetail.vue"),
      },
      {
        path: "/cooperation-case", //合作案例
        name: "cooperation-case",

        meta: {
          showChild: true,
          showSearch: true,
          title: "成功案例",
          breadName: "cooperation-case",
        },
        component: () => import("@/views/CooperationCase.vue"),
      },
      {
        path: "/cooperation-case/detail", // 合作案例详情
        name: "cooperation-case-detail",
        meta: {
          showChild: true,
          title: "成功案例详情",
          breadName: "cooperation-case",
        },
        component: () => import("@/views/CooperationCaseDetail.vue"),
      },
      {
        path: "/enterprise-case/detail", // 合作案例详情
        name: "enterprise-case-detail",
        meta: {
          showChild: true,
          title: "成功案例详情",
          breadName: "enterprise-case",
        },
        component: () => import("@/views/EnterpriseCaseDetail.vue"),
      },

      {
        path: "/dateless-brand", //品牌加盟
        name: "dateless-brand",
        meta: {
          showChild: true,
          showSearch: true,
          title: "品牌加盟",
          breadName: "dateless-brand",
        },
        component: () => import("@/views/DatelessBrand.vue"),
      },
      {
        path: "/dateless-brand/detail", // 品牌加盟详情
        name: "dateless-brand-detail",
        meta: {
          showChild: true,
          title: "品牌加盟详情",
          breadName: "dateless-brand",
        },
        component: () => import("@/views/DatelessBrandDetail.vue"),
      },
      {
        path: "/jobs", //人才招聘
        name: "jobs",
        meta: {
          showChild: true,
          showSearch: true,
          title: "人才招聘",
          breadName: "jobs",
        },
        component: () => import("@/views/Jobs.vue"),
      },
      {
        path: "/jobs-hunt", //人才招聘
        name: "jobs-hunt",
        meta: {
          showChild: true,
          showSearch: true,
          title: "求职求租",
          breadName: "jobs-hunt",
        },
        component: () => import("@/views/JobHunt.vue"),
      },
      {
        path: "/jobs-hunt-detail", //人才招聘
        name: "jobs-hunt-detail",
        meta: {
          showChild: true,
          showSearch: true,
          title: "求职求租",
          breadName: "jobs-hunt-detail",
        },
        component: () => import("@/views/JobHuntDetail.vue"),
      },
      {
        path: "/jobs/detail", //岗位详情
        name: "jobs-detail",
        meta: {
          showChild: true,
          breadName: "jobs",
        },
        component: () => import("@/views/JobsDetail.vue"),
      },
      {
        path: "/fresh-food", // 生鲜摊位
        name: "fresh-food",
        meta: {
          showChild: true,
          showSearch: true,
          title: "生鲜摊位",
          breadName: "fresh-food",
        },
        component: () => import("@/views/FreshFood.vue"),
      },
      {
        path: "/food-stall", //食堂档口
        name: "food-stall",
        meta: {
          showChild: true,
          showSearch: true,
          title: "食堂档口",
          breadName: "food-stall",
        },
        component: () => import("@/views/FoodStall.vue"),
      },
      {
        path: "/canteen-labor", //食堂外包
        name: "canteen-labor",
        meta: {
          showChild: true,
          showSearch: true,
          title: "食堂外包",
          breadName: "canteen-labor",
        },
        component: () => import("@/views/CanteenLabor.vue"),
      },
      {
        path: "/canteen", //食堂
        name: "canteen",
        meta: {
          showChild: true,
          showSearch: true,
          title: "食堂",
          breadName: "canteen",
        },
        component: () => import("@/views/Canteen.vue"),
      },
      {
        path: "/canteen-outsourcing", // 食堂劳务
        name: "canteen-outsourcing",
        meta: {
          showChild: true,
          showSearch: true,
          title: "食堂劳务",
          breadName: "canteen-outsourcing",
        },
        component: () => import("@/views/CanteenOutsourcing.vue"),
      },
      {
        path: "/canteen-outsourcing/detail", // 食堂劳务详情
        name: "canteen-outsourcing-detail",
        meta: {
          showChild: true,
          title: "食堂劳务详情",
          breadName: "canteen-outsourcing",
        },
        component: () => import("@/views/CanteenTransferDetail.vue"),
      },

      {
        path: "/market-resources/detail", // 食堂劳务详情
        name: "market-resources-detail",
        meta: {
          showChild: true,
          title: "食堂劳务详情",
          breadName: "market-resources",
        },
        component: () => import("@/views/CanteenTransferDetail.vue"),
      },
      {
        path: "/fresh-food/detail", // 食堂劳务详情
        name: "fresh-food-detail",
        meta: {
          showChild: true,
          title: "食堂劳务详情",
          breadName: "fresh-food",
        },
        component: () => import("@/views/CanteenTransferDetail.vue"),
      },
      {
        path: "/canteen-bidding", // 食堂招标
        name: "canteen-bidding",
        meta: {
          showChild: true,
          showSearch: true,
          title: "食堂招标",
          breadName: "canteen-bidding",
        },
        component: () => import("@/views/CanteenBidding.vue"),
      },
      {
        path: "/canteen-bidding/detail", // 食堂招标
        name: "canteen-bidding-detail",
        meta: {
          showChild: true,
          title: "食堂招标详情",
          breadName: "canteen-bidding",
        },
        component: () => import("@/views/CanteenTransferDetail.vue"),
      },
      {
        path: "/canteen-transfer", // 食堂转让
        name: "canteen-transfer",
        meta: {
          showChild: true,
          showSearch: true,
          title: "食堂转让",
          breadName: "canteen-transfer",
        },
        component: () => import("@/views/CanteenTransfer.vue"),
      },
      {
        path: "/canteen-transfer/detail", // 食堂转让转让
        name: "canteen-transfer-detail",
        meta: {
          showChild: true,
          showSearch: false,
          breadName: "canteen-transfer",
        },
        component: () => import("@/views/CanteenTransferDetail.vue"),
      },
      {
        path: "/food-stall/detail", //食堂档口
        name: "food-stall-detail",
        meta: {
          showChild: true,
          showSearch: false,
          title: "食堂档口",
          breadName: "food-stall",
        },
        component: () => import("@/views/CanteenTransferDetail.vue"),
      },

      {
        path: "/canteen-labor/detail", //食堂档口
        name: "canteen-labor-detail",
        meta: {
          showChild: true,
          showSearch: false,
          title: "食堂档口",
          breadName: "canteen-labor",
        },
        component: () => import("@/views/CanteenTransferDetail.vue"),
      },
      {
        path: "/smart-canteen", // 智慧食堂
        name: "smart-canteen",
        meta: {
          showChild: true,
          showSearch: true,
          title: "智慧食堂",
          breadName: "smart-canteen",
        },
        component: () => import("@/views/SmartCanteen.vue"),
      },
      {
        path: "/smart-canteen/detail", // 智慧食堂
        name: "smart-canteen-detail",
        meta: {
          showChild: true,
          breadName: "smart-canteen",
        },
        component: () => import("@/views/SmartCanteenDetail.vue"),
      },
      {
        path: "/canteen-calculator", // 食堂计算器
        name: "canteen-calculator",
        meta: {
          showChild: true,
          breadName: "canteen-calculator",
        },
        component: () => import("@/views/CanteenCalculator.vue"),
      },
      {
        path: "/canteen-evaluation", // 食堂计算器
        name: "canteen-evaluation",
        meta: {
          showChild: true,
          breadName: "canteen-evaluation",
        },
        component: () => import("@/views/CanteenEvaluation.vue"),
      },
      {
        path: "/group-meal", // 团餐企业
        name: "group-meal",
        meta: {
          showChild: true,
          showSearch: true,
          title: "团餐企业",
          breadName: "group-meal",
        },
        component: () => import("@/views/GroupMeal.vue"),
      },
      {
        path: "/food-send", // 食材配送
        name: "food-send",
        meta: {
          showChild: true,
          showSearch: true,
          title: "食材配送",
          breadName: "food-send",
        },
        component: () => import("@/views/foodSend.vue"),
      },

      {
        path: "/industry", // 行业资讯
        name: "industry",
        meta: {
          showChild: true,
          showSearch: true,
          title: "行业资讯",
          breadName: "industry",
        },
        component: () => import("@/views/Industry.vue"),
      },
      {
        path: "/industry/detail", // 资讯详情
        name: "industry-detail",
        meta: {
          showChild: true,
          breadName: "industry",
        },
        component: () => import("@/views/IndustryDetail.vue"),
      },
      {
        path: "/kefu", // 客服
        name: "kefu",
        meta: {
          showChild: true,
          title: "联系客服",
          breadName: "kefu",
        },
        component: () => import("@/views/kefu.vue"),
      },
      {
        path: "/memberinfo", // 资讯详情
        name: "member-info",
        meta: {
          showChild: false,
          breadName: "memberinfo",
        },
        component: () => import("@/views/MemberInfo.vue"),
      },
      {
        path: "/about", // 资讯详情
        name: "about",
        meta: {
          showChild: true,
          breadName: "about",
        },
        component: () => import("@/views/About.vue"),
      },
      {
        path: "/user", // 用户
        name: "user",
        meta: {
          showChild: false,
          breadName: "user", // 面包屑匹配
          title: "账户设置",
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/AccountSettings.vue"),
      },
      {
        path: "/user/messages", // 用户消息
        name: "user",
        meta: {
          showChild: false,
          title: "用户消息",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/UserMessages.vue"),
      },
      {
        path: "/user/browsing-record", // 用户浏览
        name: "user",
        meta: {
          showChild: false,
          title: "用户浏览记录",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/UserBrowsingRecord.vue"),
      },
      {
        path: "/user/comment", // 我的评论
        name: "user",
        meta: {
          showChild: false,
          title: "我的评论",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/UserCommentLog.vue"),
      },

      {
        path: "/user/PointsDetail", // 我的评论
        name: "PointsDetail",
        meta: {
          showChild: false,
          title: "积分明细",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/PointsDetail.vue"),
      },
      {
        path: "/user/start-list", // 用户收藏
        name: "start-list",
        meta: {
          showChild: false,
          title: "用户收藏记录",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/UserStartList.vue"),
      },
      {
        path: "/user/order-list", // 用户收藏
        name: "order-list",
        meta: {
          showChild: false,
          title: "我的订单",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/UserOrderList.vue"),
      },
      {
        path: "/user/order-detail", // 用户收藏
        name: "order-detail",
        meta: {
          showChild: false,
          title: "订单详情",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/UserOrderDetail.vue"),
      },
      {
        path: "/user/public-list", // 用户收藏
        name: "public-list",
        meta: {
          showChild: false,
          title: "我的发布",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/UserPublicList.vue"),
      },
      {
        path: "/user/user-center", // 用户中心
        name: "user-center",
        meta: {
          showChild: false,
          title: "我的会员",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/UserCenter.vue"),
      },
      {
        path: "/user/open-membership", // 开通会员
        name: "open-membership",
        meta: {
          showChild: false,
          title: "开通会员",
          breadName: "user", // 面包屑匹配
          showUserChild: false, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/OpenMembership.vue"),
      },
      {
        path: "/user/help-center", // 帮助中心
        name: "help-center",
        meta: {
          showChild: false,
          title: "帮助中心",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/HelpCenter.vue"),
      },
      {
        path: "/user/business-consultation", //业务资讯
        name: "business-consultation",
        meta: {
          showChild: false,
          title: "业务咨询",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/BusinessConsultation.vue"),
      },
      {
        path: "/user/news-detail", //业务资讯
        name: "news-detail",
        meta: {
          showChild: false,
          title: "帮助中心详情",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/NewsDetail.vue"),
      },
      {
        path: "/user/account-settings", //账户设置
        name: "account-settings",
        meta: {
          showChild: false,
          title: "账户设置",
          breadName: "user", // 面包屑匹配
          showUserChild: true, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/AccountSettings.vue"),
      },
      {
        path: "/user/certification", //实名认证
        name: "certification",
        meta: {
          showChild: false,
          title: "实名认证",
          breadName: "user", // 面包屑匹配
          showUserChild: false, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/Certification.vue"),
      },
      {
        path: "/user/publish", //实名认证
        name: "user-publish",
        meta: {
          showChild: false,
          breadName: "user", // 面包屑匹配
          title: "免费发布",
          showUserChild: false, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/Publish.vue"),
      },
      {
        path: "/user/publishedit", //实名认证
        name: "user-publish-edit",
        meta: {
          showChild: false,
          title: "编辑发布",
          breadName: "user", // 面包屑匹配
          showUserChild: false, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/PublishEdit.vue"),
      },
      {
        path: "/service-detail", //实名认证
        name: "service-detail",
        meta: {
          showChild: true,
          breadName: "user", // 面包屑匹配
          showUserChild: false, // 用户页面 左侧的导航部分·
        },
        component: () => import("@/views/ServiceDetail.vue"),
      },
    ],
  },
];
export default home;

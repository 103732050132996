import Home from './navContent'



export const Login = [
  {
    path: "/",
    redirect: "/login",
    meta: {
      menuHide: true,
      title: '登录',
      noValidatePrivilege: true,
     
    },
  },
  {
    path: "/login",
    name: "login",
    meta: {
      menuHide: true,
      title: '登录',
      noValidatePrivilege: true,
      login:true
    },
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/', //首页
        name: 'login',
        meta: {
          breadName: 'login',
          title: '登录',
          showChild: false,
          login:true
        },
        component: () => import('@/views/Login.vue'),
      },
      {
        path: '/forgotpass', //首页
        name: 'forgotpass',
        meta: {
          breadName: 'forgotpass',
          title: '忘记密码',
          showChild: false,
          login:true
        },
        component: () => import('@/views/ForgotPass.vue'),
      },
      {
        path: '/login/wechat_login', //首页
        name: 'wechatlogin',
        meta: {
          breadName: 'wechatlogin',
          title: '绑定手机号',
          showChild: false,
          login:true
        },
        component: () => import('@/views/WechatLogin.vue'),
      },
      {
        path: '/register', //首页
        name: 'register',
        meta: {
          breadName: 'register',
          title: '注册',
          showChild: false,
          login:true
        },
        component: () => import('@/views/Register.vue'),
      },
    ]
  },
];
 
export const routers = [
  ...Home,
  ...Login
];


import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //页面meta标签
    meta: {},
    provinceName: "",
    showList: [],
  },
  getters: {},
  mutations: {
    CHANGE_META_INFO(state, metaInfo) {
      state.meta = metaInfo;
    },
    CHANGE_PROVICE(state, name) {
      state.provinceName = name;
    },
    PUSH_NAME(state, name) {
      state.showList.push(name);
    },
    DELETE_NAME(state, name) {
      let i = state.showList.indexOf(name);
      console.log(i);
      if (i != -1) {
        state.showList.splice(i, i + 1);
      }
    },
  },
  actions: {},
  modules: {},
});

// 创建一个 axios
import axios from "axios";
import router from "@/router";
// 引入接口文件
import APIConfig from "@/server/apiConfig.js";

const http = axios.create({
  baseURL: "https://www.shitangzixun.com",
});

// 定义响应拦截器 -->token值无效时,清空token,并强制跳转登录页
http.interceptors.response.use(
  function (response) {
    // 响应状态码为 2xx 时触发成功的回调，形参中的 response 是“成功的结果”
    if (response.data.code === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("userinfo");
      router.push({ name: "login" });
    }
    return response;
  },
  function (error) {
    // console.log(error)
    // 响应状态码不是 2xx 时触发失败的回调，形参中的 error 是“失败的结果”
    if (error.response.status === 401) {
      // 无效的 token
      // 1.清空token
      localStorage.removeItem("token");
      localStorage.removeItem("userinfo");
      this.$router.push({ name: "login" });
    }
    return Promise.reject(error);
  }
);

// 封装导出一个myrequest函数，接收两个参数（api名字，和发送请求要传给服务端的参数）
export const myrequest = function (apiName, data) {
  //apiName是一个变量，所以要用下标方式获取
  const { url, method } = APIConfig[apiName];
  if (!data) {
    data = {};
  }
  var token = localStorage.getItem("token");
  let local = localStorage.getItem("provinceInfo");
  console.log(local);

  const options = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      platform: "pc",
      token: token,
      provinceid: local ? local : "",
    },
    url,
    method,
  };
  // 如果是get 数据属性叫params
  // 如果是post 数据属性叫data
  if (method.toUpperCase() === "GET") {
    options.params = data;
  }
  if (method.toUpperCase() === "POST") {
    options.data = data;
  }
  return http(options);
};
// 抛出 暴露
export default http;

import Vue from "vue";
import VueRouter from "vue-router";
import { routers } from "./routers";

Vue.use(VueRouter);

// const routes = [
// {
//   path: "/",
//   redirect: "/login",
//   meta: {
//     menuHide: true,
//     title: '登录',
//     noValidatePrivilege: true
//   },
// },

// ]

const router = new VueRouter({
  routes: routers,
  mode: "hash",
});
router.beforeEach(async (to, from, next) => {
  next();
});
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});
export default router;

<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view :key="$route.fullPath" />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/es/locale/zh_CN";
// 在node_modules中找到（可切换各种语言）
export default {
  name: "App",

  data() {
    return {
      locale: zhCN,
    };
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

img:hover {
  transform: scale(1.02) !important;
  transition: all 0.5s ease;
}
</style>
